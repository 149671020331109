import React from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
import { Outlet } from 'react-router-dom';

export default function BasePageLayout({ pageClassname = '' }) {
    return (<site-wrapper class={(pageClassname === '') ? null : pageClassname}>
        <Header />
        <hr className="header-divider"/>
        <main className="main-content" role="main">
            <Outlet />
        </main>
        <Footer />
    </site-wrapper>)
}
