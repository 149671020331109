import React from 'react'
import { Helmet } from 'react-helmet-async'
import { initializeAnalytics } from '@scrippsproduct/scripps-external-component-library/dist/utils/analytics'

const ENV = process.env.REACT_APP_ENV
const GA_ID = process.env.REACT_APP_GA_MEASUREMENT_ID 

export default function SEO({ 
    title, 
    description = `ION is the destination for your favorite binge-worthy dramas! Anchored by a collection of critically acclaimed shows to keep you entertained all day long.`, 
    name = 'ION',
    image = null, 
    type = 'article', 
    robots = 'index' 
}) {

    return (
        // `onChangeClientState` callback initializes Google Analytics.
        // This happens after the page's metadata (including the `<title>` tag) is updated, 
        // ensuring that the page title is accurately tracked in GA4.
        <Helmet onChangeClientState={() => initializeAnalytics(name, ENV, GA_ID)}>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta name='description' content={description} />

            { /* Facebook tags */ }
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            { (image !== null) && <meta property="og:image" content={image}/> }

            { /* Twitter tags */ }
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            { (image !== null) && <meta name="twitter:image" content={image} /> }

            <meta name="robots" content={robots} />
        </Helmet>
    )
}