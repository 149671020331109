import * as MockModel from './MockModel'
import * as ProdModel from './ProdModel'

const Model = process.env.REACT_APP_ENV === 'production' ? ProdModel : MockModel

const getResource = Model.getResource
const getResourceList = Model.getResourceList

export {
    getResourceList,
    getResource,
};