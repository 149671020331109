import { CtaBlock, GetAirdateWrapper } from "@scrippsproduct/scripps-external-component-library"
import { generateHrefFromProgramSlug, setAltText } from '@scrippsproduct/scripps-external-component-library/dist/utils/helpers'

const SCHEDULE_ENDPOINT = process.env.REACT_APP_SCHEDULE_V2
const NETWORK = process.env.REACT_APP_NETWORK

const setSlideImgUrl = (slide) => {
    return slide.image?.[0]?.image?.publicUrl || ''
}

const buildCta = (slide) => {
    const program = slide.program?.[0]?.item?.[0];
    // It's only going to be transformed into an href link if it's a series or movie type
    const programTypeValid = program?.slug && ["SeriesItem", "MovieItem"].includes(program.__typename);
    const href = slide.linkUrl || (programTypeValid && generateHrefFromProgramSlug(program));
    
    return {
        href,
        type: (slide.brightcoveID) ? 
            "play-video" :
            (slide.linkType === "EXTERNAL")
                ? "external-link"
                : (slide.linkType === "INTERNAL" || href) && "internal-link",
        target: (slide.brightcoveID) ? null : slide.linkTarget,
        relatedResourceId: slide.brightcoveID ?? null,
    };
};

const determineAspectRatio = ({slide, carouselType}) => {
    if (carouselType === 'BANNER') return 1710/850
    if (carouselType === 'FEATURED') return 395/583
    if (slide.image[0].image?.width && slide.image[0].image?.height) return slide.image[0].image.width/slide.image[0].image.height
    
    return 0.677966101694915
}

const transformShowSlideLayout = (slide, index, type = 'series') => {
    let program = {}
    let props = {}
    
    if (type === 'series') {
        program = slide.program?.[0]?.item?.[0] || {}
        props = {            
            series: {
                title: slide.title,
                bannerImage: {
                    image: {
                        publicUrl: setSlideImgUrl(slide),
                        altText: setAltText(slide), // NOTE: This is being passed in correctly, but the ShowSlide layout is not set up to accept the alt text, it autopopulates it to ''. Will need to update the module in order for this to be able to be populated correctly.
                        sources: [
                            {
                                media: 'min-width: 1800px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.publicUrl}`,
                                ]
                            },
                            {
                                media: 'min-width: 1500px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                                    `${slide.image?.[0]?.image?.publicUrl} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1200px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 900px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 600px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 300px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                                ]
                            }
                        ]
                    }
                },
                bannerMobileImage: {
                    image: {
                        publicUrl: slide.mobileImage?.[0]?.image?.publicUrl || '',
                        altText: setAltText(slide),
                    },
                },
                logoImage: {
                    image: {
                        publicUrl: program.logoImage?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src || '',
                        width: program.logoImage?.[0]?.image?.width,
                        height: program.logoImage?.[0]?.image?.height,
                        sizes: [
                            {
                                srcSets: [
                                    {
                                        src: program.logoImage?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src || '',                                    }
                                ],
                            },
                        ],
                    },
                },
                slug: program.slug || '',
                seriesPrefix: program.seriesPrefix || '',
                preventDetailPage: program.preventDetailPage ?? true,
            },
            titlePosition: slide.headlineAlignment,
            titleColor: slide.textColor,
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (index > 1) ? true : false,
            fetchPriority: (index > 1) ? 'low' : 'high',
            decoding: (index > 1) ? 'async' : 'sync',
            hasSeriesPages: true,
            NETWORK,
            SCHEDULE_ENDPOINT,
        }
    } else if (type === 'film') {
        program = slide.program?.[0]?.item?.[1] || {}
        props = {
            series: {
                title: slide.title,
                bannerImage: {
                    image: {
                        publicUrl: setSlideImgUrl(slide),
                        altText: setAltText(slide), // NOTE: This is being passed in correctly, but the ShowSlide layout is not set up to accept the alt text, it autopopulates it to ''. Will need to update the module in order for this to be able to be populated correctly.
                        sources: [
                            {
                                media: 'min-width: 1800px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.publicUrl}`,
                                ]
                            },
                            {
                                media: 'min-width: 1500px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                                    `${slide.image?.[0]?.image?.publicUrl} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1200px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 900px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 600px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 300px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                                ]
                            }
                        ]
                    }
                },
                bannerMobileImage: {
                    image: {
                        publicUrl: slide.mobileImage?.[0]?.image?.publicUrl || '',
                        altText: setAltText(slide),
                    },
                },
                programType: type,
                slug: program.slug || '',
                titleCode: program.titleCode || '',
                preventDetailPage: program.preventDetailPage ?? true,
            },
            titlePosition: slide.headlineAlignment,
            titleColor: slide.textColor,
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (index > 1) ? true : false,
            fetchPriority: (index > 1) ? 'low' : 'high',
            decoding: (index > 1) ? 'async' : 'sync',
            hasSeriesPages: true,
            NETWORK,
            SCHEDULE_ENDPOINT,
        }
    }

    return {
        layout: 'ShowSlide',
        props,
    }
};

const transformImageCardLayout = (slide, carouselType='') => {
    const determineContent = () => {
        if (carouselType === 'FEATURED') { 
            if (slide.program?.length > 0) {
                let programId = ''
                let programType = 'series'
                if (slide.program?.[0]?.item?.[0]?.seriesPrefix) {
                    programId = slide.program?.[0]?.item?.[0]?.seriesPrefix
                }
                if (slide.program?.[0]?.item?.[1]?.titleCode) {
                    programId = slide.program[0].item?.[1]?.titleCode
                    programType = 'film'
                }
                
                return <GetAirdateWrapper 
                    programId={programId}
                    programType={programType} 
                    separatorType={(window.matchMedia('(min-width: 1470px)').matches) ? 'pipe' : 'single-line-break'}
                    NETWORK={NETWORK}
                    SCHEDULE_ENDPOINT={SCHEDULE_ENDPOINT}
                />
            }
        }
        if (slide.headline) return slide.headline

        return ''
    }

    return {
        title: slide.title,
        layout: 'ImageCard',
        props: {
            id: slide.position,
            image: {
                url: setSlideImgUrl(slide),
                altText: setAltText(slide), 
                aspectRatio: determineAspectRatio({slide, carouselType}),
                sources: [
                    {
                        media: 'min-width: 1800px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.publicUrl}`,
                        ]
                    },
                    {
                        media: 'min-width: 1500px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                            `${slide.image?.[0]?.image?.publicUrl} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 1200px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 900px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 600px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 300px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 1px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                        ]
                    }
                ],
            },
            mobileImage: {
                url: slide.mobileImage?.[0]?.image?.publicUrl || '',
                aspectRatio: slide.mobileImage[0]?.image?.width/slide.mobileImage[0]?.image?.height,
            },
            content: determineContent(),
            cta: buildCta(slide),
            fullCardClickable: (Boolean(buildCta(slide).href) || Boolean(slide.brightcoveID)),
            relatedResourceId: (slide.brightcoveID) ? slide.brightcoveID : null,
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (carouselType === 'BANNER') ? false : true,
        }
    }
}

const transformTwoColumnSlideLayout = (slide) => ({
    title: slide.title, // NOTE: doesn't look like this is even being used anymore, can we omit? 
    layout: 'TwoColumnSlide',
    props: {
        columnOneContent: <CtaBlock 
                title={slide.title}
                content={slide.headline}
                cta={{
                    label: slide.ctaLabel,
                    ...buildCta(slide),
                }}
                ctaButtonStyle="gradient"
                titleImageUrl={slide.logoImage?.[0]?.image?.publicUrl || ''}
                type={buildCta(slide).type}
                btnGradientStartColor={slide.ctaGradientStartOverride || `var(--theme-color-500)`}
                btnGradientEndColor={slide.ctaGradientEndOverride || `var(--theme-color-400)`} 
                // btnFlatColors={{
                //     bgColor: slide.ctaGradientStartOverride || `#000`,
                //     bgHoverColor: slide.ctaGradientEndOverride || `#555`,
                //     color: '#fff',
                //     hoverColor: '#fff',
                //     borderColor: slide.ctaGradientStartOverride || `#000`,
                //     borderHoverColor: slide.ctaGradientEndOverride || `#555`
                // }}
            />,
            columnTwoContent: 
            <div 
                className="image-container" 
                style={{
                    "--image-position": `${slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50}% ${slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50}%`
                }}
            >
                <img 
                    src={slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[0]?.src} 
                    srcSet={`${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[0]?.src} 300w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src} 600w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[2]?.src} 900w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src} 1000w`}
                    alt={setAltText(slide)} 
                />
            </div>
    },
});

const transformPromotion = (slide) => {
    const program = slide.program?.[0]?.item?.[0];
    // It's only going to be transformed into an href link if it's a series or movie type
    const programTypeValid = program?.slug && ["SeriesItem", "MovieItem"].includes(program.__typename);
    const cta = {
        type:
            slide.brightcoveID ? "play-video"
            : slide.linkType === "EXTERNAL" ? "external-link"
            : (slide.linkType === "INTERNAL" || slide.linkUrl || programTypeValid) && "internal-link",
        label: slide.ctaLabel,
        href: !slide.brightcoveID ? slide.linkUrl || generateHrefFromProgramSlug(program) : null,
        position: (slide.linkUrl || slide.brightcoveID || programTypeValid) && "end", // CTA will not show up if position isn't set to "end", so this logic prevents slides without links (of any type) from mistakenly displaying a non-functional link.
        relatedResourceId: slide.brightcoveID,
    };

    return {
        id: slide.position,
        image: {
            url: setSlideImgUrl(slide),
            altText: setAltText(slide),
            aspectRatio: determineAspectRatio({slide}),
            sources: [
                {
                    media: 'min-width: 2000px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1800px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1200px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 600px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                    ],
                },
            ]
        },
        title: slide.title,
        content: slide.body,
        cta,
    }
};

export {
    transformShowSlideLayout,
    transformImageCardLayout,
    transformTwoColumnSlideLayout,
    transformPromotion
}