import { useQuery } from 'react-query'
import { getResource } from '../models/GraphQLModel'

const useResource = ({ cacheName = '', resourceName, resourceId, queryVars }) => {
    return useQuery([(cacheName !== '') ? cacheName : resourceName, {
        resourceName, 
        resourceId, 
        queryVars,
    }], getResource)
}

export default useResource