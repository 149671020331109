import React from 'react'
import { useLocation } from 'react-router-dom'
import Redirect from '@scrippsproduct/scripps-external-component-library/dist/utils/Redirect'
import useResource from '../custom-hooks/useResource'
import PageNotFound from '../pages/PageNotFound'

function PageRouter() {
    const location = useLocation()
    const queryObj = {
        cacheName: `page:${location.pathname.replace('/', '')}`,
        resourceName: 'page',
        resourceId: location.pathname.replace('/', ''),
        queryVars: { 
            path: location.pathname,
        },
    }

    const page = useResource(queryObj)

    if (page.isSuccess) {
        if (page.data.Page.pageType === null) return <PageNotFound /> 
        
        return <Redirect to={`${page.data.Page.pageType.toLowerCase()}${location.pathname}`} />
    }

    if (page.isError) {
        return <PageNotFound />
    }
}

export default PageRouter